import type { AppDispatch } from 'store';

const { BROWSER_FEATURES } = require('@sp/ui/settings');
const { refetchDataAfterSkatPI } = require('actions/dashboard');

class FetchTaxAndPensionsInfoController {
  onDoneCallback?: () => void;
  onDoneNavigation?: () => void;
  onSkipNavigation?: () => void;
  pendingRefetchingPromise?: any;
  browserService?: Window | null;
  isFetching: boolean;
  terminationSituation: {
    [key: string]: boolean;
  };
  isWindowBlocked: boolean;

  constructor() {
    this.onDoneCallback = undefined;
    this.onDoneNavigation = undefined;
    this.onSkipNavigation = undefined;
    this.browserService = undefined;
    this.pendingRefetchingPromise = undefined;
    this.isFetching = false;
    this.terminationSituation = {
      isTryingToTerminateFetchingProcess: false,
      hasTerminatedFetchingProcess: false,
    };
    this.isWindowBlocked = false;
  }

  resetController() {
    this.isFetching = false;
    this.pendingRefetchingPromise = undefined;
    this.browserService = undefined;
    this.terminationSituation = {
      isTryingToTerminateFetchingProcess: false,
      hasTerminatedFetchingProcess: false,
    };
  }

  getIsWindowBlocked() {
    return this.isWindowBlocked;
  }

  updateOnDoneCallback(callback: () => void) {
    this.onDoneCallback = callback;
  }

  updateOnDoneNavigation(navigation: () => void) {
    this.onDoneNavigation = navigation;
  }

  updateOnSkipNavigation(navigation: () => void) {
    this.onSkipNavigation = navigation;
  }

  updateIsFetching(value: boolean) {
    this.isFetching = value;
  }

  updateTerminationSituation(situation: Record<string, boolean>) {
    this.terminationSituation = {
      ...this.terminationSituation,
      ...situation,
    };
  }

  updateAndOpenBrowserService(url: string, isFlowTax = false, ssoTicket?: string) {
    if (isFlowTax && (!this.browserService || this.browserService?.closed)) {
      this.browserService = global.window.open(url, '_blank', BROWSER_FEATURES);
    } else if (ssoTicket) {
      this.isWindowBlocked = false;
      const window = global.window.open(
        `/pensionsInfo.html?url=${url}&ssoTicket=${ssoTicket}`,
        '_blank',
        BROWSER_FEATURES
      );
      if (!window) {
        this.isWindowBlocked = true;
      }
      this.browserService = window;
    }
  }

  removeBrowserService() {
    this.browserService = undefined;
  }

  removePendingRefetchingPromise() {
    this.pendingRefetchingPromise = undefined;
  }

  getTerminationSituation() {
    return this.terminationSituation;
  }

  getIsFetching() {
    return this.isFetching;
  }

  getCallbacks() {
    return {
      onDoneCallback: this.onDoneCallback,
      onDoneNavigation: this.onDoneNavigation,
      onSkipNavigation: this.onSkipNavigation,
    };
  }

  getBrowserService() {
    return this.browserService;
  }

  waitForPendingRefetchingPromise({
    dispatch,
    fetchFail,
  }: {
    dispatch: AppDispatch;
    fetchFail: (failType: string) => void;
  }) {
    return new Promise(async (resolve) => {
      try {
        /* NOTE: If you fetch one area and skip the next
        then initiatePendingRefetchingPromise is never executed
        and we should manually deliver the refetching action */
        if (!this.pendingRefetchingPromise) {
          await dispatch(refetchDataAfterSkatPI());
          resolve(true);
        } else {
          this.pendingRefetchingPromise.then((res: boolean) => {
            if (res) {
              resolve(res);
            } else {
              fetchFail('somethingWentWrong');
            }
          });
        }
      } catch (e) {
        fetchFail('somethingWentWrong');
        resolve(true);
      }
    });
  }

  async initiatePendingRefetchingPromise({ dispatch }: { dispatch: AppDispatch }) {
    if (!this.pendingRefetchingPromise) {
      this.pendingRefetchingPromise = new Promise(async (resolve) => {
        try {
          await dispatch(refetchDataAfterSkatPI());
          resolve(true);
        } catch (e) {
          resolve(false);
        }
      });
    }
  }
}

const fetchTaxAndPensionsInfoController = new FetchTaxAndPensionsInfoController();

export default fetchTaxAndPensionsInfoController;
