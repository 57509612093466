import React, { useContext } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'hooks/redux';
import useI18n from 'hooks/useI18n';
import { useBackAndForwardButtonNavigation } from './hooks/useBackAndForwardButtonNavigation';
import { selectIsFlowTax } from 'selectors/fetchTaxAndPensionsInfo';
import { selectIsDeviceTypeDesktop, selectIsDeviceTypeMobile } from 'selectors/device';
import { selectFlowControl } from 'selectors/flowControl';
import { selectDeeplink } from 'selectors/deeplink';
import { FetchTaxAndPensionsInfoContext } from 'context/FetchTaxAndPensionsInfoContext';
import MenuPageContainer from 'containers/v2/pages/MenuPage/MenuPageV2';
import type { RootState } from 'store';
import { isWeb } from 'helpers/platform';
import fetchTaxAndPensionsInfoController from './fetchTaxAndPensionsInfoController';

const { default: Pig } = require('@sp/ui/base/Loader/Pig');
const { default: Margin } = require('@sp/ui/base/Margin');
const { Body, H3 } = require('@sp/ui/v2/typography');

const CenterAlignment = styled.View`
  justify-content: center;
  align-items: center;
`;

const selectFetching = (state: RootState) => ({
  isDesktop: selectIsDeviceTypeDesktop(state),
  isMobile: selectIsDeviceTypeMobile(state),
  isFlowTax: selectIsFlowTax(state),
  flowControl: selectFlowControl(state),
  deeplink: selectDeeplink(state),
});

const Fetching: React.FC = () => {
  const { onClose, updateFetchingState, fetchingState } = useContext(
    FetchTaxAndPensionsInfoContext
  );
  const { COLORS } = useTheme();
  const i18n = useI18n(['fetchTaxAndPensionsInfo']);
  const { isDesktop, isMobile, isFlowTax } = useAppSelector(selectFetching, shallowEqual);
  const { backButtonNavigation } = useBackAndForwardButtonNavigation();
  const { getIsWindowBlocked } = fetchTaxAndPensionsInfoController;

  const isWindowBlocked = getIsWindowBlocked();

  return (
    <MenuPageContainer
      title={i18n.t('fetchTaxAndPensionsInfo|fetchingPensionsInfo')}
      backButtonRoute={backButtonNavigation}
      actions={[
        {
          text: i18n.t('fetchTaxAndPensionsInfo|stopFetching'),
          onPress: () => {
            updateFetchingState({
              ...fetchingState,
              fetchingController: undefined,
            });

            onClose();
          },
          type: 'primary',
        },
      ]}
    >
      <CenterAlignment>
        {isWindowBlocked && (
          <Margin marginTop="xxxl">
            <Body>
              Det ser ud til at PensionsInfo vinduet blev blokeret. Deaktiver popup blockere og prøv
              igen.
            </Body>
          </Margin>
        )}
        {!isWindowBlocked && (
          <Margin marginTop={isDesktop ? '' : 'md'} horizontal="md">
            <Pig
              color={
                // special condition for mobile web menupage template with white background color
                isDesktop || (isWeb && isMobile)
                  ? COLORS.V2_PRIMARY_BACKGROUND
                  : COLORS.V2_SECONDARY_BACKGROUND
              }
            />
            <Margin marginTop="lg" marginBottom="md">
              <H3 textAlign="center">{i18n.t('fetchTaxAndPensionsInfo|retrievingInformation')}</H3>
            </Margin>
            <Body textAlign="center">
              {i18n.t(
                `fetchTaxAndPensionsInfo|${
                  isFlowTax
                    ? 'pleaseWaitWhileFetchingYourTaxInformation'
                    : 'pleaseWaitWhileFetchingYourPensionsInformation'
                }`
              )}
            </Body>
          </Margin>
        )}
      </CenterAlignment>
    </MenuPageContainer>
  );
};

export default Fetching;
