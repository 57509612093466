import React from 'react';
import Description from 'areas/dashboard/Description';
import PropTypes from 'prop-types';
import withComponentContainer from 'decorators/withComponentContainer';
import { getPensionVsTodayRatioAdvice } from 'areas/dashboard/helpers';
import { formatMoney } from 'helpers/string';
import {
  selectIncomeWhenRetired,
  selectRetirementAge,
  selectShowGrossAmount,
} from 'selectors/dashboard';
import { RETIREMENT } from '../../constants/dashboardTypes';
import { Body, Link } from '@sp/ui/typography';
import { openLink } from '../../../../helpers/linking';
import Margin from '@sp/ui/base/Margin';

const RetirementDescription = ({
  i18n,
  retirementAge,
  incomeWhenRetired,
  status,
  recommendedAge,
  noRecommendationType,
  showGrossAmount,
  infoAccessibilityLabel,
  modalDescription,
  modalTitle,
}) => {
  let title;
  let description;
  const age = recommendedAge || retirementAge;
  const pensionVsTodayRatioAdvice =
    status ||
    (incomeWhenRetired &&
      getPensionVsTodayRatioAdvice(incomeWhenRetired?.pensionVsTodayRatio, RETIREMENT));

  switch (true) {
    case noRecommendationType === 'disposableIncomeBelowRecommendationLimit':
      title = i18n.t('dashboard|disposableIncomeBelowRecommendationLimitTitle');
      description = i18n.t('dashboard|disposableIncomeBelowRecommendationLimitDescription');
      break;
    case pensionVsTodayRatioAdvice === 'alert':
    case noRecommendationType === 'noSatisfyingRecommendedPension':
      title = i18n.t('dashboard|pensionVsTodayRatioAdviceTitleAlert', { age });
      description = showGrossAmount
        ? i18n.t('dashboard|pensionVsTodayRatioAdviceDescriptionAlertWithGross')
        : i18n.t('dashboard|pensionVsTodayRatioAdviceDescriptionAlert', {
            incomeWhenRetired: incomeWhenRetired ? formatMoney(incomeWhenRetired, true) : '? kr.',
          });
      break;
    case pensionVsTodayRatioAdvice === 'warning':
      title = i18n.t('dashboard|pensionVsTodayRatioAdviceTitleWarning', { age });
      description = showGrossAmount
        ? i18n.t('dashboard|pensionVsTodayRatioAdviceDescriptionWarningWithGross')
        : i18n.t('dashboard|pensionVsTodayRatioAdviceDescriptionWarning', {
            incomeWhenRetired: incomeWhenRetired ? formatMoney(incomeWhenRetired, true) : '? kr.',
          });
      break;
    default:
      title = i18n.t('dashboard|pensionVsTodayRatioAdviceTitleDefault', { age });
      description = showGrossAmount
        ? i18n.t('dashboard|pensionVsTodayRatioAdviceTitleDefaultWithGross')
        : i18n.t('dashboard|pensionVsTodayRatioAdviceDescriptionDefault');
      break;
  }

  const preDescription = showGrossAmount
    ? i18n.t('dashboard|pensionVsTodayRatioAdvicePreDescriptionWithGross')
    : i18n.t('dashboard|pensionVsTodayRatioAdvicePreDescription');

  return (
    <Description
      title={title}
      preDescription={
        <Margin marginTop="md" marginBottom="md">
          <Body>
            Der er forskellige regler for, hvornår du tidligst kan starte udbetaling af pension. Læs
            mere om{' '}
            <Link
              onPress={() => {
                const url = i18n.t('link|earliestPensionAge');
                openLink(url, '_blank');
              }}
            >
              tidligste udbetalingsalder
            </Link>
            {` eller ring til os, hvis du vil vide mere.\nDu finder kontaktoplysningerne i "Mere" menuen.`}
          </Body>
        </Margin>
      }
      description={`${preDescription}\n\n${description}`}
      modalTitle={modalTitle}
      infoAccessibilityLabel={infoAccessibilityLabel}
      modalDescription={modalDescription}
    />
  );
};

RetirementDescription.propTypes = {
  i18n: PropTypes.object.isRequired,
  retirementAge: PropTypes.number,
  recommendedAge: PropTypes.number,
  status: PropTypes.string,
  incomeWhenRetired: PropTypes.shape({
    currency: PropTypes.string,
    amount: PropTypes.number,
    pensionVsTodayRatio: PropTypes.number,
  }),
  noRecommendationType: PropTypes.string,
  showGrossAmount: PropTypes.bool.isRequired,
  infoAccessibilityLabel: PropTypes.string.isRequired,
  modalDescription: PropTypes.node.isRequired,
  modalTitle: PropTypes.node.isRequired,
};

RetirementDescription.defaultProps = {
  incomeWhenRetired: undefined,
  status: undefined,
  recommendedAge: undefined,
  retirementAge: undefined,
  noRecommendationType: undefined,
};

const mapStateToProps = (state) => ({
  incomeWhenRetired: selectIncomeWhenRetired(state),
  retirementAge: selectRetirementAge(state),
  showGrossAmount: selectShowGrossAmount(state),
});

export default withComponentContainer({ mapStateToProps })(RetirementDescription);
