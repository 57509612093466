import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components/native';
import { useRoute } from '@react-navigation/native';
import withComponentContainer from 'decorators/withComponentContainer';
import FormSectionWithModal from 'containers/layout/forms/FormSectionWithModal';
import Tracking from 'services/tracking';
import { Body } from '@sp/ui/typography';
import ShowMore from 'containers/dashboard/ShowMore';
import { renderStringOrComponent } from '@sp/ui/helpers/component';
import ModalContentWithList from 'areas/dashboard/ModalContentWithList';

const Description = ({
  i18n,
  theme,
  title,
  preDescription,
  description,
  modalTitle,
  modalDescription,
  infoAccessibilityLabel,
}) => {
  const route = useRoute();
  return (
    <FormSectionWithModal
      disablePadding
      title={title}
      accessibilityLabel={description}
      infoAccessibilityLabel={infoAccessibilityLabel}
      preDescription={preDescription}
      description={
        description && (
          <ShowMore
            showMoreElement={<Body color={theme.COLORS.PRIMARY_LINK}>{i18n.t('readMore')}</Body>}
            showLessElement={<Body color={theme.COLORS.PRIMARY_LINK}>{i18n.t('readLess')}</Body>}
          >
            {renderStringOrComponent(description, Body)}
          </ShowMore>
        )
      }
      onInfoPress={() => {
        Tracking.trackEvent({ area: route.name, action: 'ClickInfo' });
      }}
      modalContent={<ModalContentWithList title={modalTitle} description={modalDescription} />}
    />
  );
};

Description.propTypes = {
  i18n: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  preDescription: PropTypes.node,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  modalTitle: PropTypes.string,
  modalDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  infoAccessibilityLabel: PropTypes.string,
};

Description.defaultProps = {
  preDescription: undefined,
  description: undefined,
  modalTitle: undefined,
  infoAccessibilityLabel: undefined,
};

export default withComponentContainer({
  hasLocalNotifications: false,
})(withTheme(Description));
